<i18n>
{
  "en": {
    "config-name": "Presentation",
    "config-description": "How do you want the website to look like?",
    "name": "Name",
    "host": "Host",
    "description": "Description",
    "logo-large": "Large logo",
    "logo-large-hint": "Preferable SVG format, landscape (aspect ratio about 6:1), transparent, suitable for display on <strong>dark</strong> background",
    "logo-small": "Small logo",
    "logo-small-hint": "Preferable SVG format, square, transparent, suitable for display on <strong>light</strong> background",
    "features-name": "Features",
    "features-description": "What Features do you need?",
    "public": "Public site",
    "public-description": "Posts can be seen by everyone without being registered.",
    "signup_enabled": "Signup",
    "signup_enabled-description": "A visitor is allowed to sign up with his e-mail address.",
    "comments_enabled": "Comments",
    "comments_enabled-description": "A user who is signed in is allowed to comment on posts."
  },
  "de": {
    "config-name": "Darstellung",
    "config-description": "Wie soll sich die Website präsentieren?",
    "name": "Name",
    "host": "Host",
    "description": "Beschreibung",
    "logo-large": "Großes Logo",
    "logo-large-hint": "Möglichst SVG-Format, Querformat (Seitenverhältnis etwa 6:1), transparent, geeignet für Darstellung auf <strong>dunklem</strong> Hintergrund",
    "logo-small": "Kleines Logo",
    "logo-small-hint": "Möglichst SVG-Format, Quadratisch, transparent, geeignet für Darstellung auf <strong>hellem</strong> Hintergrund",
    "features-name": "Funktionsumfang",
    "features-description": "Welche Funktionen sollen bereitgestellt werden?",
    "public": "Öffentliche Website",
    "public-description": "Beiträge sind für jedermann auch ohne Registrierung einsehbar.",
    "signup_enabled": "Registrierung",
    "signup_enabled-description": "Ein Besucher darf sich mit seiner E-Mail-Adresse registrieren.",
    "comments_enabled": "Kommentierung",
    "comments_enabled-description": "Ein angemeldeter Besucher darf Beiträge kommentieren."
  }
}
</i18n>

<template>
  <div>
    <form @submit.prevent="$emit('submit')">
      <input type="submit" class="invisible">

      <FormGroup
        :name="$t('config-name')"
        :description="$t('config-description')"
        :first="true"
      >
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6">
            <text-input
              v-model="form.name"
              :errors="$page.props.errors && $page.props.errors.name"
              :label="$t('name')"
              :autofocus="true"
            />
          </div>

          <div class="col-span-6">
            <text-input
              v-model="form.host"
              :errors="$page.props.errors && $page.props.errors.host"
              :label="$t('host')"
            />
          </div>

          <div class="col-span-6">
            <textarea-input
              v-model="form.description"
              :errors="$page.props.errors && $page.props.errors.description"
              :label="$t('description')"
            />
          </div>

          <div class="col-span-6">
            <label class="form-label">
              {{ $t('logo-large') }}
            </label>

            <p
              class="mt-2 text-sm leading-normal text-gray-500"
              v-html="$t('logo-large-hint')"
            />

            <div class="mt-2 flex flex-wrap items-center">
              <div class="w-full rounded bg-gray-700 p-2 lg:w-2/3">
                <img
                  :src="form.logo.large_url"
                  :alt="$t('logo-large')"
                  class="mx-auto"
                  width="450"
                  height="75"
                >
              </div>

              <div class="w-full pt-5 lg:w-1/3 lg:pl-5 lg:pt-0">
                <file-input
                  v-model="form.logo_large"
                  :errors="$page.props.errors && $page.props.errors.logo_large"
                  type="file"
                  accept="image/png,image/jpeg,image/jpg,image/gif,image/svg+xml"
                  name="logo_large"
                />
              </div>
            </div>
          </div>

          <div class="col-span-6">
            <label class="form-label">
              {{ $t('logo-small') }}
            </label>

            <!-- eslint-disable vue/no-v-html -->
            <p
              class="mt-2 text-sm leading-normal text-gray-500"
              v-html="$t('logo-small-hint')"
            />
            <!-- eslint-enable vue/no-v-html -->

            <div class="mt-2 flex flex-wrap items-center">
              <div
                class="rounded border border-dashed border-gray-300 bg-white p-2"
              >
                <img
                  :src="form.logo.small_url"
                  :alt="$t('logo-small')"
                  width="64"
                  height="64"
                >
              </div>

              <div class="w-full pt-5 lg:w-1/3 lg:pl-5 lg:pt-0">
                <file-input
                  v-model="form.logo_small"
                  :errors="$page.props.errors && $page.props.errors.logo_small"
                  type="file"
                  accept="image/png,image/jpeg,image/jpg,image/gif,image/svg+xml"
                  name="logo_small"
                />
              </div>
            </div>
          </div>
        </div>
      </FormGroup>

      <FormGroup
        :name="$t('features-name')"
        :description="$t('features-description')"
        :last="true"
      >
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6">
            <toggle-input
              v-model="form.public"
              :errors="$page.props.errors && $page.props.errors.public"
              :label="$t('public')"
              :description="$t('public-description')"
            />
          </div>

          <div class="col-span-6">
            <toggle-input
              v-model="form.signup_enabled"
              :errors="$page.props.errors && $page.props.errors.signup_enabled"
              :label="$t('signup_enabled')"
              :description="$t('signup_enabled-description')"
            />
          </div>

          <div class="col-span-6">
            <toggle-input
              v-model="form.comments_enabled"
              :errors="
                $page.props.errors && $page.props.errors.comments_enabled
              "
              :label="$t('comments_enabled')"
              :description="$t('comments_enabled-description')"
            />
          </div>
        </div>
      </FormGroup>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
