<i18n>
{
  "en": {
    "my-comment": "My comment",
    "save": "Save"
  },
  "de": {
    "my-comment": "Mein Kommentar",
    "save": "Abschicken"
  }
}
</i18n>

<template>
  <form class="relative flex flex-wrap items-start" @submit="submit">
    <input type="submit" class="invisible absolute">

    <div class="flex w-full flex-wrap py-3">
      <textarea-input
        v-model="form.content"
        :errors="$page.props.errors && $page.props.errors.content"
        class="w-full py-3"
        rows="3"
        :placeholder="$t('my-comment')"
      />

      <action-button
        as="button"
        type="button"
        :loading="sending"
        @click="submit"
      >
        {{ $t('save') }}
      </action-button>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      sending: false,
      form: {
        content: '',
      },
    };
  },

  methods: {
    submit() {
      this.$inertia.post(this.$routes.post_comments(this.post), this.form, {
        preserveScroll: true,
        onStart: () => (this.sending = true),
        onFinish: () => {
          this.sending = false;
          if (!this.$page.props.errors) {
            this.form = {};
          }
        },
      });
    },
  },
};
</script>
