<i18n>
{
  "en": {
    "all": "All",
    "new": "New",
    "draft": "Draft",
    "photo": "Photo",
    "video": "Video",
    "audio": "Audio",
    "text": "Text",
    "default": "Default",
    "calendar": "Kalender"
  },
  "de": {
    "all": "Alle",
    "new": "Neu",
    "draft": "Entwurf",
    "photo": "Foto",
    "video": "Video",
    "audio": "Audio",
    "text": "Text",
    "default": "Standard",
    "calendar": "Kalender"
  }
}
</i18n>

<template>
  <div
    class="hide-scrollbar flex items-center justify-between overflow-x-auto px-5 sm:px-10"
  >
    <div>
      <nav
        ref="nav"
        aria-label="Filter"
        class="flex items-center bg-white text-sm font-semibold dark:bg-gray-700"
      >
        <inertia-link
          v-for="item in filterItems"
          :key="item.title"
          :href="$routes.posts(item.filter, currentView)"
          class="flex items-center py-3 pr-4 focus:outline-none"
          :only="['posts', 'params']"
        >
          <icon
            v-if="item.iconName"
            :name="item.iconName"
            class="mr-1 size-4"
          />

          <div
            class="rounded bg-white p-1 xl:hover:bg-gray-100 dark:bg-gray-700 xl:dark:hover:bg-gray-800"
            :class="item.class"
          >
            <span>
              {{ item.title }}
            </span>

            <span class="ml-1 hidden text-gray-400 sm:inline">
              {{ item.count.toLocaleString() }}
            </span>
          </div>
        </inertia-link>
      </nav>

      <hr
        v-if="activeFilterItem"
        class="bg-black transition-all duration-200 ease-in-out"
        :style="{
          width: activeFilterItem.width,
          marginLeft: activeFilterItem.marginLeft,
          height: '2px',
        }"
      >
    </div>

    <div class="flex items-center">
      <inertia-link
        v-for="item in viewItems"
        :key="item.title"
        :href="$routes.posts(currentFilter, item.view)"
        :only="['posts', 'params']"
        class="ml-1 rounded p-1"
        :class="{
          'bg-gray-200 dark:bg-gray-800': item.view == currentView,
          'hover:bg-gray-100 dark:hover:bg-gray-600': item.view != currentView,
        }"
      >
        <icon :name="item.icon" class="size-5" />
      </inertia-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      required: true,
    },
    counter: {
      type: Object,
      required: true,
    },
  },

  data() {
    var filterItems = [];

    filterItems.push({
      title: this.$t('all'),
      filter: 'all',
      width: 0,
      marginLeft: 0,
      count:
        Object.values(this.counter).reduce((a, b) => a + b) -
        (this.counter.unread || 0) -
        (this.counter.draft || 0),
    });

    filterItems.push(
      {
        title: this.$t('photo'),
        filter: 'photos',
        width: 0,
        marginLeft: 0,
        count: this.counter.photo,
      },
      {
        title: this.$t('video'),
        filter: 'videos',
        width: 0,
        marginLeft: 0,
        count: this.counter.video,
      },
      {
        title: this.$t('audio'),
        filter: 'audios',
        width: 0,
        marginLeft: 0,
        count: this.counter.audio,
      },
      {
        title: this.$t('text'),
        filter: 'texts',
        width: 0,
        marginLeft: 0,
        count: this.counter.text,
      },
    );

    if (this.counter.unread > 0) {
      filterItems.push({
        title: this.$t('new'),
        filter: 'unread',
        width: 0,
        marginLeft: 0,
        count: this.counter.unread,
        class: 'bg-red-100 hover:bg-red-200',
      });
    }

    if (this.counter.draft > 0) {
      filterItems.push({
        title: this.$t('draft'),
        filter: 'draft',
        width: 0,
        marginLeft: 0,
        count: this.counter.draft,
        class: 'bg-blue-100 hover:bg-blue-200',
      });
    }

    const viewItems = [
      {
        title: this.$t('default'),
        icon: 'view-grid',
        view: null,
      },
      {
        title: this.$t('calendar'),
        icon: 'calendar',
        view: 'calendar',
      },
    ];

    return {
      filterItems,
      viewItems,
    };
  },

  computed: {
    activeFilterItem() {
      return this.filterItems.find(
        (item) => item.filter === this.currentFilter,
      );
    },

    currentFilter() {
      return this.params.filter || 'all';
    },

    currentView() {
      return this.params.view;
    },
  },

  mounted() {
    this.calcItems();
  },

  methods: {
    calcItems() {
      if (!this.$refs.nav) return;

      let menuWidth = this.$refs.nav.offsetWidth;
      let elements = this.$refs.nav.getElementsByTagName('a');

      var leftMargins = [];
      Array.from(elements).forEach((el, index) => {
        var widthPercent = (el.offsetWidth / menuWidth) * 100;
        var tempMarginLeft = 0;
        if (index != 0) {
          tempMarginLeft = leftMargins.reduce((a, b) => a + b);
        }

        this.filterItems[index].width = 'calc(' + widthPercent + '% - 20px)';
        this.filterItems[index].marginLeft = tempMarginLeft + '%';

        leftMargins.push(widthPercent);
      });
    },
  },
};
</script>

<style lang="css">
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>
