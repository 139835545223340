<template>
  <img
    v-if="size === 'small'"
    :src="smallUrl"
    width="64"
    height="64"
    alt="Logo"
    class="size-10 rounded-full bg-gray-300 p-1"
  >
  <img
    v-else-if="size === 'large'"
    :src="largeUrl"
    width="450"
    height="75"
    alt="Logo"
  >
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      required: true,
    },
  },
  computed: {
    smallUrl() {
      return document.querySelector('link[rel="logo"][size="small"]').href;
    },
    largeUrl() {
      return document.querySelector('link[rel="logo"][size="large"]').href;
    },
  },
};
</script>
