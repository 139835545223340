<i18n>
{
  "en": {
    "no-logins": "None"
  },
  "de": {
    "no-logins": "Keine"
  }
}
</i18n>

<template>
  <table v-if="user.login_activities.length > 0" class="min-w-full text-xs">
    <tbody>
      <tr v-for="(login_activity, index) in user.login_activities" :key="index">
        <th class="py-2 align-top">
          <icon
            v-if="login_activity.success"
            name="check-circle"
            class="mr-1.5 size-5 shrink-0 text-emerald-500"
          />
          <icon
            v-else
            name="x-circle"
            class="mr-1.5 size-5 shrink-0 text-red-500"
            :title="login_activity.failure_reason"
          />
        </th>
        <th class="whitespace-nowrap px-3 py-2 align-top leading-snug">
          {{ $d(new Date(login_activity.created_at), 'datetime') }}
        </th>
        <td class="px-3 py-2 align-top leading-snug">
          <span>{{ login_activity.ip }}</span>
          <span v-if="login_activity.ip_location">
            &minus;
            {{ login_activity.ip_location }}
          </span>
          <br>
          <div class="mt-1">
            {{ login_activity.user_agent }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div v-else>
    {{ $t('no-logins') }}
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>
