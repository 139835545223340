<template>
  <intersect @enter.once="onEnter">
    <div :class="{ relative: !isFaded }">
      <!-- Show the placeholder in the background -->
      <blurhash-img
        v-if="!isFaded"
        :hash="blurhash || defaultBlurhash"
        :aspect-ratio="height / width"
        class="transition-opacity duration-500"
        :class="isLoaded ? 'opacity-0' : 'opacity-100'"
      />

      <!-- Show the real image on the top and fade in after loading -->
      <img
        ref="image"
        :width="width"
        :height="height"
        v-bind="$attrs"
        :class="{
          'opacity-100': isLoaded,
          'opacity-0': !isLoaded,
          'absolute left-0 top-0 transition-opacity duration-500': !isFaded,
        }"
      >
    </div>
  </intersect>
</template>

<script>
import Intersect from 'vue-intersect';

export default {
  components: {
    Intersect,
  },

  inheritAttrs: false,

  props: {
    src: {
      type: String,
      required: true,
    },
    blurhash: {
      type: String,
      required: false,
      default: null,
    },
    width: {
      type: Number,
      default: 1,
    },
    height: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      defaultBlurhash: 'L4F$Y8%M00fQ~qayM{j[00ayxuay',
      isLoaded: false,
      isFaded: false,
    };
  },

  methods: {
    onEnter() {
      // Image is visible (means: has entered the viewport), so start loading by setting the src attribute
      this.$refs.image.src = this.src;

      this.$refs.image.onload = () => {
        // Image is loaded, so start fading in
        this.isLoaded = true;

        setTimeout(() => {
          // After the image is faded in (by CSS), the BlurhashImg can be removed to speed up scrolling
          this.isFaded = true;
        }, 2000); // fading in
      };
    },
  },
};
</script>
