<i18n>
{
  "en": {
    "profile": "Profile",
    "profile-description": "This information will be displayed publicly so be careful what you share.",
    "login-activity": "Last Logins",
    "login-activity-description": "Last 10 Logins",
    "account": "Account data",
    "account-description": "Security and authorization",
    "name": "Name",
    "email": "Email",
    "password": "Change password",
    "password-confirmation": "Confirm changed password",
    "owner": "Owner",
    "subscribed_to_newsletter": "Newsletter subscribed",
    "site_name": "Site",
    "avatar": "Avatar"
  },
  "de": {
    "profile": "Profil",
    "profile-description": "Wird öffentlich angezeigt, daher Vorsicht bei der Angabe von Informationen",
    "login-activity": "Letzte Anmeldungen",
    "login-activity-description": "Die letzten 10 Logins.",
    "account": "Zugangskonto",
    "account-description": "Sicherheit und Zugriffsberechtigung",
    "Name": "Name",
    "email": "E-Mail",
    "password": "Neues Passwort",
    "password-confirmation": "Neues Passwort bestätigen",
    "owner": "Eigentümer",
    "subscribed_to_newsletter": "Newsletter abonniert",
    "site_name": "Seite",
    "avatar": "Avatar"
  }
}
</i18n>

<template>
  <form class="relative" @submit.prevent="$emit('submit')">
    <input type="submit" class="invisible absolute">

    <FormGroup
      :name="$t('profile')"
      :description="$t('profile-description')"
      :first="true"
    >
      <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6 sm:col-span-3">
          <text-input
            v-model="form.name"
            :errors="$page.props.errors && $page.props.errors.name"
            :label="$t('name')"
            :autofocus="true"
          />
        </div>
      </div>
      <div class="mt-5">
        <label class="form-label">
          {{ $t('avatar') }}
        </label>
        <div class="mt-2 flex items-center">
          <span
            class="inline-block size-12 overflow-hidden rounded-full bg-gray-100"
          >
            <img
              v-if="form.avatar_url"
              class="size-full text-gray-300"
              :src="form.avatar_url"
              :alt="$t('avatar')"
              width="64"
              height="64"
            >
            <icon name="user-circle-black" class="size-full text-gray-300" />
          </span>
          <span class="ml-5">
            <file-input
              v-model="form.avatar"
              :errors="$page.props.errors && $page.props.errors.avatar"
              type="file"
              accept="image/png,image/jpeg,image/jpg,image/gif"
            />
          </span>
        </div>
      </div>
    </FormGroup>

    <FormGroup
      :name="$t('account')"
      :description="$t('account-description')"
      :last="!form.login_activities"
    >
      <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6 sm:col-span-4">
          <text-input
            v-model="form.email"
            type="email"
            :errors="$page.props.errors && $page.props.errors.email"
            :label="$t('email')"
          />
        </div>

        <div v-if="can.manage_user" class="col-span-6 sm:col-span-3">
          <div v-if="site">
            <toggle-input
              v-model="form.site_permissions[0].owner"
              :label="$t('owner')"
            />

            <br>

            <toggle-input
              v-model="form.site_permissions[0].subscribed_to_newsletter"
              :label="$t('subscribed_to_newsletter')"
            />
          </div>

          <table v-else>
            <tr>
              <th class="p-1 text-left">
                {{ $t('site_name') }}
              </th>
              <th class="p-1 text-left">
                {{ $t('owner') }}
              </th>
              <th class="p-1 text-left">
                {{ $t('subscribed_to_newsletter') }}
              </th>
            </tr>

            <tr
              v-for="site_permission in form.site_permissions"
              :key="site_permission.id"
            >
              <td class="p-1">
                <a
                  :href="site_permission.url"
                  target="_blank"
                  class="underline"
                >
                  {{ site_permission.name }}
                </a>
              </td>
              <td class="p-1">
                <toggle-input v-model="site_permission.owner" />
              </td>
              <td class="p-1">
                <toggle-input
                  v-model="site_permission.subscribed_to_newsletter"
                />
              </td>
            </tr>
          </table>
        </div>

        <div class="col-span-6">
          <text-input
            v-model="form.password"
            class="w-full py-3"
            type="password"
            autocomplete="off"
            :errors="$page.props.errors && $page.props.errors.password"
            :label="$t('password')"
          />

          <text-input
            v-model="form.password_confirmation"
            class="w-full py-3"
            type="password"
            autocomplete="off"
            :errors="
              $page.props.errors && $page.props.errors.password_confirmation
            "
            :label="$t('password-confirmation')"
          />
        </div>
      </div>
    </FormGroup>

    <FormGroup
      v-if="form.login_activities"
      :name="$t('login-activity')"
      :description="$t('login-activity-description')"
      :last="true"
    >
      <LoginActivities :user="form" />
    </FormGroup>
  </form>
</template>

<script>
import LoginActivities from './_LoginActivities.vue';

export default {
  components: {
    LoginActivities,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    can: {
      type: Object,
      required: true,
    },
    site: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
